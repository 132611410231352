export const COLORS = {
  TABLE: {
    FONT: "white",
    BORDER: "#777",
    BACKGROUND: "#333",
  },
  GAMES: {
    BORDER: "grey",
    TIMEZONE: "#888",
  },
  RULES: {
    BORDER: "grey",
    BACKGROUND: "#333",
    CLOSE_BUTTON: "red",
  },
};
