import { keyframes, styled } from "styled-components";
import { COLORS as C } from "./colors";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Cell = styled.div`
  &.animate {
    opacity: 0;
    transform: translateY(-20px);
    animation: ${fadeIn} 0.3s ease forwards;
  }
`;

export const Team = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  img {
    margin: 0 0.75rem;
  }
  span {
    margin-right: 0.75rem;
  }
`;

export const TiebreakContainer = styled.div`
  position: relative;
  display: block;
`;

export const TiebreakContent = styled.div`
  position: absolute;
  background-color: ${C.CELL.BACKGROUND};
  color: ${C.CELL.FONT};
  font-size: 0.75em;
  white-space: nowrap;
  top: 0.3rem;
  left: 140%;
  padding-right: 0.5rem;
`;
