import React, { createContext, useContext, useEffect, useState } from "react";

type DataContextType = {
  data: any;
  isLoading: boolean;
};

const DataContext = createContext<DataContextType | undefined>(undefined);

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData debe ser usado dentro de un DataProvider");
  }
  return context;
};

type DataProviderProps = {
  initialData: any;
  children: React.ReactNode;
};

export const DataProvider: React.FC<DataProviderProps> = ({
  initialData,
  children,
}) => {
  const [data, setData] = useState<any>(initialData);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setData(initialData);
    setIsLoading(false);
  }, [initialData]);

  return (
    <DataContext.Provider value={{ data, isLoading }}>
      {children}
    </DataContext.Provider>
  );
};
