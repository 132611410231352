import * as S from "./styles";
import { useParams } from "react-router-dom";
import { GroupDetail } from "pages/GroupDetail";
import { useData } from "DataContext";
import { CompetitionType, RulesGroupType, RulesType } from "types";

export const Competition = () => {
  const { competitionId, groupId } = useParams();
  const { data } = useData();

  const COMPETITIONS_DATA: CompetitionType[] = data.COMPETITIONS_DATA;
  const RULES: RulesType = data.RULES;

  const competition =
    COMPETITIONS_DATA.find((c) => c.id === competitionId) ||
    COMPETITIONS_DATA[0];

  const group =
    competition.groups.find((g) => g.id === groupId) || competition.groups[0];

  const qualification = group?.qualification || competition.qualification;
  const DEFAULT_RULES: RulesGroupType[] = [
    { rulesIds: [RULES.PTS.id, RULES.DG.id, RULES.GF.id], type: "simple" },
  ];
  const rules = competition.rules || DEFAULT_RULES;

  return (
    <S.Competition className="Competition">
      <GroupDetail
        groupData={group}
        competition={competition}
        qualification={qualification}
        rules={rules}
      />
    </S.Competition>
  );
};
