import { styled } from "styled-components";
import { COLORS as C } from "./colors";

export const Game = styled.div`
  margin: 0.75rem 0;
`;

export const Date = styled.p`
  font-size: 0.75em;
  color: ${C.DATE};
  text-align: center;
  margin: 0.25rem 0;
`;

type ScoreProps = {
  $actuallyPlayed: boolean;
};

export const Score = styled.div<ScoreProps>`
  display: grid;
  width: 100%;
  grid-template-columns: calc(50% - 6rem) 2rem 3rem 2rem 3rem 2rem calc(
      50% - 6rem
    );

  div {
    align-self: center;
    margin: 0 0.25rem;
  }

  div.badge {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    width: 100%;
    box-sizing: border-box;
    text-align: center;

    color: ${C.FONT};
    font-weight: bold;
    background-color: ${({ $actuallyPlayed }) =>
      $actuallyPlayed ? C.BACKGROUND_PLAYED : C.BACKGROUND};
    border: thin solid ${C.BORDER};
    border-radius: 0.5rem;
  }

  div:nth-child(4) {
    cursor: pointer;
  }

  div:nth-child(1) {
    text-align: right;
  }
  div:nth-child(2),
  div:nth-child(3),
  div:nth-child(4),
  div:nth-child(5) {
    text-align: center;
  }
  div:nth-child(6) {
    text-align: left;
  }

  // quitar botones html de los input number
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    appearance: textfield; /* Standard */
    -moz-appearance: textfield; /* Firefox */
  }
`;

export const GameCards = styled(Score)`
  margin: 0.5rem 0;
  grid-template-columns: calc(50% - 4.25rem) 2.5rem 3.5rem 2.5rem calc(
      50% - 4.25rem
    );
`;
