import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { child, get, getDatabase, onValue, ref, set } from "firebase/database";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyBl4_xWAIGR1gKlDhDvuocV8t2L7SZUxVE",
  authDomain: "quien-pasa-v2.firebaseapp.com",
  databaseURL: "https://quien-pasa-v2-default-rtdb.firebaseio.com",
  projectId: "quien-pasa-v2",
  storageBucket: "quien-pasa-v2.appspot.com",
  messagingSenderId: "321379149938",
  appId: "1:321379149938:web:a83dedb68edf97c39f7aeb",
  measurementId: "G-9VN78N323Q",
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
export const analytics = getAnalytics(app);

const {
  NODE_ENV,
  REACT_APP_RECAPTCHA_SITE_KEY = "",
  REACT_APP_DEBUG_TOKEN = "",
} = process.env;
if (NODE_ENV === "development") {
  (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = REACT_APP_DEBUG_TOKEN;
}
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(REACT_APP_RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
});

export const writeDB = (tree: string, data: object) => {
  set(ref(db, tree), data);
};

const dbRef = ref(db);

export const readDB = (tree: string) => {
  ///tmp
  get(child(dbRef, tree))
    .then((snapshot) => {
      if (snapshot.exists()) {
        console.log(snapshot.val());
        return snapshot.val();
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const readDB2 = (tree: string) => {
  ///tmp
  const refTree = ref(db, tree);
  onValue(
    refTree,
    (snapshot) => {
      const data = snapshot.val();
      console.log(data);
    },
    { onlyOnce: true }
  );
};

export const fetchDataFromDDBB = async () => {
  const REACT_APP_ROOT_BD: string = process.env.REACT_APP_ROOT_BD || "";
  try {
    const dataRef = ref(db, REACT_APP_ROOT_BD);
    const snapshot = await get(dataRef);
    return snapshot.val();
  } catch (error) {
    console.error("Error al obtener datos de Firebase:", error);
    throw error;
  }
};
