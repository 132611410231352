import { FC } from "react";
import * as S from "./styles";
import { BadgeProps } from "types";

const EMPTY_BADGE =
  "https://upload.wikimedia.org/wikipedia/he/8/88/Flag_of_unknown_country.svg";

export const Badge: FC<BadgeProps> = ({ url }) => {
  return <S.Badge src={url || EMPTY_BADGE} alt="badge" />;
};
