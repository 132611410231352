import * as S from "./styles";
import logo from "assets/img/logo_512x512.png";

export const Loading = () => {
  return (
    <S.Loading>
      <img src={logo} alt="logo" />
    </S.Loading>
  );
};
