import { styled } from "styled-components";
import { COLORS as C } from "./colors";

export const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${C.BACKGROUND};

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 25%;
  }
`;
