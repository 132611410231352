import { Fragment } from "react";
import * as S from "./styles";
import { NavLink } from "react-router-dom";
import { CompetitionType } from "types";

export const GroupBar: React.FC<{ competition: CompetitionType }> = ({
  competition,
}) => {
  return (
    <S.GroupBar className="GroupBar" $numGroups={competition.groups.length}>
      {competition.groups.map((g) => (
        <Fragment key={g.id}>
          {g.id === "3" && <span />}
          <NavLink to={`/${competition.id}/${g.id}`}>
            <div>{g.label}</div>
          </NavLink>
        </Fragment>
      ))}
    </S.GroupBar>
  );
};
