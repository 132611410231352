import { keyframes, styled } from "styled-components";
import { COLORS as C } from "./colors";

export const GroupDetail = styled.div`
  max-width: 40rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0.5em;
  color: ${C.TABLE.FONT};
  background-color: ${C.TABLE.BACKGROUND};
`;

export const Thead = styled.thead``;

type ThProps = {
  $column?: string;
};

export const Th = styled.th<ThProps>`
  border-bottom: thin solid ${C.TABLE.BORDER};
  text-align: center;
  width: 3rem;
  min-width: ${({ $column }) => $column === "Equipo" && "12rem"};
  cursor: ${({ $column }) => $column === "Criterio de desempate" && "pointer"};
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  height: 2rem;

  td:first-child {
    background-color: ${({ color }) => color};
    border-radius: 0.5rem;
    width: 4rem;
  }
`;

export const Td = styled.td`
  text-align: center;
  white-space: nowrap;

  div.badge {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Cell = styled.div`
  &.animate {
    opacity: 0;
    transform: translateY(-20px);
    animation: ${fadeIn} 0.3s ease forwards;
  }
`;

export const Team = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  img {
    margin: 0 0.75rem;
  }
  span {
    margin-right: 0.75rem;
  }
`;

export const Games = styled.div`
  width: 100%;
  margin-top: 1.5rem;
`;

type JornadaTitleProps = {
  $opened?: boolean;
};
export const JornadaTitle = styled.div<JornadaTitleProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: thin solid ${C.GAMES.BORDER};
  border-radius: ${({ $opened }) => ($opened ? "0.5rem 0.5rem 0 0" : "0.5rem")};
  cursor: pointer;
`;

export const Jornada = styled.div`
  border: thin solid ${C.GAMES.BORDER};
  border-radius: 0 0 0.5rem 0.5rem;
`;

export const Timezone = styled.p`
  color: ${C.GAMES.TIMEZONE};
  text-align: center;
  font-size: 0.75em;
`;

export const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;

  @media (max-width: 768px) {
    margin: 0.5rem 0;
    flex-direction: column;
  }
`;

export const CompetitionName = styled.div`
  display: flex;
  align-items: center;
  margin: 0.7rem 0;
  p {
    font-size: 1.25em;
    font-weight: bold;
    margin: 0;
    margin-left: 0.5rem;
  }
  svg {
    margin-left: 1rem;
    cursor: pointer;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

type RulesProps = {
  $isOpenRules?: boolean;
};

export const Rules = styled.div<RulesProps>`
  position: fixed;
  box-sizing: border-box;
  padding: 2rem;
  border-left: thin solid ${C.RULES.BORDER};
  background-color: ${C.RULES.BACKGROUND};
  height: 100%;
  width: 35%;
  top: 4rem;
  overflow-y: auto;
  max-height: calc(100vh - 4rem);
  right: ${({ $isOpenRules }) => ($isOpenRules ? 0 : "-35%")};
  transition: right 0.3s ease;

  @media (max-width: 768px) {
    font-size: 1rem;
    width: 100%;
    right: ${({ $isOpenRules }) => ($isOpenRules ? 0 : "-100%")};
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.25em;
  border-radius: 1rem;
  cursor: pointer;
  color: ${C.RULES.CLOSE_BUTTON};
`;

export const RuleDescription = styled.div`
  margin: 2rem 0;
  p {
    margin: 0.25rem 0;
  }
`;

export const TiebreakContainer = styled.div`
  position: relative;
  display: block;
`;

export const TiebreakContent = styled.div`
  position: absolute;
  background-color: ${C.TABLE.BACKGROUND};
  color: ${C.TABLE.FONT};
  font-size: 0.75em;
  white-space: nowrap;
  top: 0;
  left: 120%;
`;
