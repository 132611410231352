import { useState } from "react";
import * as S from "./styles";
import { NavLink, Outlet } from "react-router-dom";
import { CompetitionType } from "types";
import logo from "assets/img/logo_192x192.png";
import { useData } from "DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

export const Layout = () => {
  const { data } = useData();
  const [isOpenSidebar, setIsOpenSidebar] = useState(true);

  const competitions: CompetitionType[] = data.COMPETITIONS_DATA;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.detail === 2) {
      event.preventDefault();
    }
  };

  return (
    <S.Layout
      onMouseDown={handleMouseDown}
      onClick={() => isMobileDevice && setIsOpenSidebar(false)}
    >
      <S.Topbar>
        <img src={logo} alt="logo" />
        <header>
          <h1>¿Quién pasa?</h1>
        </header>
        <p>{process.env.REACT_APP_VERSION}</p>
      </S.Topbar>

      <S.Wrapper>
        <S.Sidebar $isOpenSidebar={isOpenSidebar}>
          <S.SidebarContent>
            {competitions.map((competition) => (
              <NavLink
                key={competition.id}
                to={competition.id}
                className={`${competition.inProgress && "inProgress"}`}
              >
                <div>{competition.name}</div>
              </NavLink>
            ))}
          </S.SidebarContent>
        </S.Sidebar>
        <S.MinimizeButton
          onClick={(e) => {
            e.stopPropagation();
            setIsOpenSidebar(!isOpenSidebar);
          }}
          $isOpenSidebar={isOpenSidebar}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="xl" />
        </S.MinimizeButton>

        <S.Content>
          <Outlet context={{ setIsOpenSidebar }} />
        </S.Content>
      </S.Wrapper>
    </S.Layout>
  );
};
