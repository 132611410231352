import { styled } from "styled-components";
import { COLORS as C } from "./colors";

export const Button = styled.button`
  color: ${C.FONT};
  background-color: ${C.BACKGROUND};
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${C.BACKGROUND_HOVER};
  }

  &:disabled {
    background-color: ${C.BACKGROUND_DISABLED};
    cursor: default;
  }
`;
