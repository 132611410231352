import { DataProvider } from "DataContext";
import { GlobalStyles } from "./styles/globalStyles";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type AppProps = {
  initialData: any;
};

export const App: React.FC<AppProps> = ({ initialData }) => {
  return (
    <DataProvider initialData={initialData}>
      <GlobalStyles />
      <RouterProvider router={router} />
      <ToastContainer theme="dark" closeOnClick />
    </DataProvider>
  );
};
