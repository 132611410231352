import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {
  faArrowRotateForward,
  faArrowsRotate,
  faFloppyDisk,
  faScaleBalanced,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";

const renderIcon = (type: string) => {
  const getIcon = (type: string) => {
    switch (type) {
      case "share":
      default:
        return faShareNodes;

      case "reset":
        return faArrowRotateForward;

      case "resetToActual":
        return faArrowsRotate;

      case "save":
        return faFloppyDisk;

      case "rules":
        return faScaleBalanced;
    }
  };

  const icon = getIcon(type);
  return icon ? <FontAwesomeIcon icon={icon} /> : <></>;
};

type ButtonType = {
  label?: string;
  tooltip?: string;
  type?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

export const Button: React.FC<ButtonType> = ({
  label = "",
  tooltip = "",
  type = "",
  onClick,
  disabled = false,
}) => {
  return (
    <S.Button onClick={onClick} disabled={disabled} title={tooltip}>
      {renderIcon(type)}
      {label}
    </S.Button>
  );
};
