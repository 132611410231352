import { styled } from "styled-components";
import { COLORS as C } from "styles/colors";

type GroupBarProps = {
  $numGroups: number;
};

export const GroupBar = styled.div<GroupBarProps>`
  max-width: 40rem;
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  display: grid;
  gap: 0.5rem;
  margin-bottom: 1rem;
  grid-template-columns: repeat(
    ${({ $numGroups }) => {
      if ($numGroups <= 6) return $numGroups;
      return Math.round($numGroups / 2);
    }},
    1fr
  );

  @media (max-width: 768px) {
    grid-template-columns: repeat(
      ${({ $numGroups }) => {
        if ($numGroups <= 4) return $numGroups;
        return Math.round($numGroups / 2);
      }},
      1fr
    );
  }

  div {
    padding: 1rem;
    color: ${C.GROUP_BAR.FONT};
    background-color: ${C.GROUP_BAR.BACKGROUND};
    border-radius: 0.5rem;

    @media (max-width: 768px) {
      padding: 0.75rem;
    }
  }

  div:hover {
    background-color: ${C.GROUP_BAR.BACKGROUND_HOVER};
  }

  a {
    div {
      white-space: nowrap;
      text-align: center;
    }
    &.active {
      div {
        background-color: ${C.GROUP_BAR.BACKGROUND_ACTIVE};
      }
    }
  }
`;
