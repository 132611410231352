import {
  Navigate,
  RouteObject,
  createBrowserRouter,
  useParams,
} from "react-router-dom";
import { Layout } from "pages/Layout";
import { Competition } from "components/Competition";
import { useData } from "DataContext";
import { CompetitionType } from "types";

const RenderCompetition = () => {
  const { competitionId, groupId } = useParams();
  const { data } = useData();

  const COMPETITIONS_DATA: CompetitionType[] = data.COMPETITIONS_DATA;

  const competition = COMPETITIONS_DATA.find((c) => c.id === competitionId);
  const validCompetition = !!competition;

  const group = competition?.groups.find((g) => g.id === groupId);
  const validGroup = !!group;

  if (!validCompetition)
    return (
      <Navigate
        to={`/${COMPETITIONS_DATA[0]?.id}/${COMPETITIONS_DATA[0]?.groups[0].id}`}
        replace
      />
    );

  if (!validGroup)
    return (
      <Navigate to={`/${competition.id}/${competition.groups[0].id}`} replace />
    );

  return <Competition />;
};

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <div>Error page</div>,
    children: [
      {
        path: "/:competitionId?/:groupId?",
        element: <RenderCompetition />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
